import React, { useEffect, useState } from "react";
import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "../../hoc/withBenefitsApplication";

import AddressModel from "../../models/Address";
import { Addresses } from "../../models/Experian";
import Button from "../../components/core/Button";
import ConditionalContent from "../../components/ConditionalContent";
import Details from "../../components/core/Details";
import FieldsetAddress from "../../components/FieldsetAddress";
import FormLabel from "../../components/core/FormLabel";
import InputChoiceGroup from "../../components/core/InputChoiceGroup";
import QuestionPage from "../../components/QuestionPage";
import formatAddress from "../../utils/formatAddress";
import { isFeatureEnabled } from "src/services/featureFlags";
import { pick } from "lodash";
import routes from "../../routes";
import useFormState from "../../hooks/useFormState";
import useFunctionalInputProps from "../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../locales/i18n";

export const fields = [
  "claim.has_mailing_address",
  "claim.residential_address.line_1",
  "claim.residential_address.line_2",
  "claim.residential_address.city",
  "claim.residential_address.state",
  "claim.residential_address.zip",
  // Include `mailing_address` so validation error shows for completely empty mailing address.
  // We don't need this for `residential_address` since that defaults to a blank object, rather than null.
  "claim.mailing_address",
  "claim.mailing_address.line_1",
  "claim.mailing_address.line_2",
  "claim.mailing_address.city",
  "claim.mailing_address.sate",
  "claim.mailing_address.zip",
];

export const Address = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const { formState, getField, updateFields, clearField } = useFormState(
    pick(props, fields).claim
  );

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [backButtonLink, setBackButtonLink] = useState<string>("");
  const [submitButtonLabel, setSubmitButtonLabel] = useState<string>(
    t("pages.claimsAddress.validateAddress")
  );
  const [isReadyToSelectAddress, setIsReadyToSelectAddress] = useState(false);
  const [isAddressVerified, setIsAddressVerified] = useState(false);
  const [availableAddresses, setAvailableAddresses] = useState<Addresses[]>([]);
  const [selectedAddressKey, setSelectedAddressKey] = useState<string>("");
  const [useDefaultAddress, setUseDefaultAddress] = useState<boolean>(false);
  const [unvalidatedAddress, setUnvalidatedAddress] = useState<string>("");

  const { has_mailing_address } = formState;
  const addressSuggestionThreshold = 8;

  useEffect(() => {
    const existingMailingAddress = formState.mailing_address;
    if (formState.has_mailing_address && !existingMailingAddress) {
      updateFields({ mailing_address: {} });
    }
  }, [formState, updateFields]);

  const handleSave = async () => {
    if (isFeatureEnabled("enableAddressValidation")) {
      if (useDefaultAddress) {
        // User wants to continue with unvalidated address

        appLogic.benefitsApplications.update(claim.application_id, formState);
      } else if (selectedAddressKey) {
        // Uers wants to continue with address validated using Experian

        const addressFormatResponse =
          await appLogic.benefitsApplications.addressFormat(selectedAddressKey);

        if (addressFormatResponse) {
          const formattedAddress = addressFormatResponse.address;

          if (has_mailing_address) {
            formState.is_mailing_address_validated = true;
            updateFields({
              "mailing_address.line_1": formattedAddress.address_line_1,
              "mailing_address.line_2": formattedAddress.address_line_2,
              "mailing_address.city": formattedAddress.locality,
              "mailing_address.state": formattedAddress.region,
              "mailing_address.zip": formattedAddress.postal_code,
            });
          } else {
            formState.is_residential_address_validated = true;
            updateFields({
              "residential_address.line_1": formattedAddress.address_line_1,
              "residential_address.line_2": formattedAddress.address_line_2,
              "residential_address.city": formattedAddress.locality,
              "residential_address.state": formattedAddress.region,
              "residential_address.zip": formattedAddress.postal_code,
            });
          }

          setIsAddressVerified(true);
          appLogic.benefitsApplications.update(claim.application_id, formState);
        }
      } else if (!isAddressVerified) {
        // Perform address fields validation
        const issues = appLogic.benefitsApplications.validateAddressFields(
          formState.residential_address,
          formState.mailing_address,
          formState.has_mailing_address
        );

        if (issues?.length === 0) {
          // User either mailing or residential address for Experian address search
          // mailing address has priority over residential address
          let address;
          if (formState.has_mailing_address && formState.mailing_address) {
            address = formState.mailing_address;
          } else {
            address = formState.residential_address;
          }

          const { line_1, line_2, city, state, zip } = address;

          // This is user entered address, which is not yet validated and
          // provided as one of the choices to select as address
          setUnvalidatedAddress(
            formatAddress(line_1, line_2, city, state, zip)
          );

          // Call address search API
          const addressResponse =
            await appLogic.benefitsApplications.addressSearch(
              line_1,
              line_2,
              city,
              state,
              zip
            );

          if (addressResponse?.addresses) {
            const addressCount = addressResponse.addresses.length;

            if (addressCount > 0) {
              if (addressCount <= addressSuggestionThreshold) {
                setAvailableAddresses(addressResponse.addresses);

                // By default select the single address if there's only one suggestion
                if (addressCount === 1) {
                  handleAddressSelection(
                    addressResponse.addresses[0].global_address_key
                  );
                }
              } else {
                // Address count exceeds threshold
                setAvailableAddresses([]);
                setDisableSubmit(true);
              }
            } else {
              // Handle cases where address count is 0
              setAvailableAddresses([]);
              setDisableSubmit(true);
            }
          } else {
            // Handle cases where addressResponse is undefined or addresses is not present
            setAvailableAddresses([]);
            console.error("No addresses returned from the search.");
          }

          setIsReadyToSelectAddress(
            (addressResponse && addressResponse.ready_to_select_addresses) ||
              false
          );
          setBackButtonLink(
            `${routes.applications.address}?claim_id=${claim.application_id}`
          );
          // setBackButtonLabel("Refine Search");
          setSubmitButtonLabel(t("components.form.continueButton"));
        }
      }
    } else {
      appLogic.benefitsApplications.update(claim.application_id, formState);
    }
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const residentialAddressProps = getFunctionalInputProps(
    "residential_address"
  );
  if (!residentialAddressProps.value) {
    residentialAddressProps.value = new AddressModel({});
  }

  const mailingAddressProps = getFunctionalInputProps("mailing_address");
  if (!mailingAddressProps.value) {
    mailingAddressProps.value = new AddressModel({});
  }

  const handleAddressSelection = (key: string) => {
    setSelectedAddressKey(key);
    setUseDefaultAddress(false);
  };

  const handleDefaultAddressSelection = () => {
    setSelectedAddressKey("default_address");
    setUseDefaultAddress(true);
    setIsAddressVerified(true); // Default address doesn't need to validate using Experian
    setDisableSubmit(false);
  };

  const handleRefineSearchClick = () => {
    setIsReadyToSelectAddress(false);
    setIsAddressVerified(false);
    setSubmitButtonLabel(t("pages.claimsAddress.validateAddress"));
    setBackButtonLink(
      `${routes.applications.phoneNumber}?claim_id=${claim.application_id}`
    );
    setDisableSubmit(false);
  };

  const getAddressChoiceLabel = (count: number, t: (key: string) => string) => {
    if (count === 1) {
      return t("pages.claimsAddress.singularAddressSuggestion");
    } else if (count > 1) {
      return t("pages.claimsAddress.addressSuggestion");
    }
  };

  const getDefaultAddressDropdownLabel = (
    count: number,
    t: (key: string) => string
  ) => {
    if (count > 0) {
      return t("pages.claimsAddress.defaultAddressWithResults");
    } else {
      return t("pages.claimsAddress.cannotValidateYourAddressLabel");
    }
  };

  return isFeatureEnabled("enableAddressValidation") ? (
    <QuestionPage
      title={t("pages.claimsAddress.addressSuggestionTitle")}
      onSave={handleSave}
      continueButtonLabel={submitButtonLabel}
      backButtonLink={backButtonLink}
      disableSubmit={disableSubmit}
    >
      {!isReadyToSelectAddress ? (
        <React.Fragment>
          <FieldsetAddress
            errors={appLogic.errors}
            label={t("pages.claimsAddress.sectionLabel")}
            hint={t("pages.claimsAddress.hint")}
            {...residentialAddressProps}
          />
          <InputChoiceGroup
            {...getFunctionalInputProps("has_mailing_address")}
            choices={[
              {
                checked: has_mailing_address === false,
                label: t("pages.claimsAddress.choiceYes"),
                value: "false",
              },
              {
                checked: has_mailing_address === true,
                label: t("pages.claimsAddress.choiceNo"),
                value: "true",
              },
            ]}
            label={t("pages.claimsAddress.hasMailingAddressLabel")}
            hint={t("pages.claimsAddress.hasMailingAddressHint")}
            type="radio"
          />
          <ConditionalContent
            fieldNamesClearedWhenHidden={["mailing_address"]}
            getField={getField}
            clearField={clearField}
            updateFields={updateFields}
            visible={has_mailing_address}
          >
            <FieldsetAddress
              errors={appLogic.errors}
              label={t("pages.claimsAddress.mailingAddressLabel")}
              hint={t("pages.claimsAddress.mailingAddressHint")}
              addressType="mailing"
              {...mailingAddressProps}
            />
          </ConditionalContent>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {availableAddresses.length > 0 &&
            // If only one address is available, automatically select the radio button
            // Otherwise, show a list of available addresses to choose from
            (availableAddresses.length === 1 ? (
              <InputChoiceGroup
                name="selectedAddress"
                choices={[
                  {
                    label: availableAddresses[0].address,
                    value: availableAddresses[0].global_address_key,
                    checked: true,
                  },
                ]}
                label={getAddressChoiceLabel(availableAddresses.length, t)}
                type="radio"
              />
            ) : (
              <InputChoiceGroup
                name="selectedAddress"
                choices={availableAddresses.map((address) => ({
                  label: address.address,
                  value: address.global_address_key,
                  checked: selectedAddressKey === address.global_address_key,
                }))}
                label={getAddressChoiceLabel(availableAddresses.length, t)}
                onChange={(e) => handleAddressSelection(e.target.value)}
                type="radio"
              />
            ))}

          {(availableAddresses.length === 0 ||
            availableAddresses.length > addressSuggestionThreshold) && (
            <React.Fragment>
              <FormLabel component="legend" hint="">
                {t("pages.claimsAddress.unableToVerifyAddressLabel")}
              </FormLabel>

              <Button
                className="margin-top-4"
                type="submit"
                onClick={handleRefineSearchClick}
              >
                Refine Search
              </Button>
            </React.Fragment>
          )}
          <br></br>
          <br></br>
          <Details
            label={getDefaultAddressDropdownLabel(availableAddresses.length, t)}
          >
            <p>{t("pages.claimsAddress.defaultAddressWarningLabel")}</p>
            <InputChoiceGroup
              name="selectedAddress"
              choices={[
                {
                  label: unvalidatedAddress,
                  value: "default_address",
                  checked: selectedAddressKey === "default_address",
                },
              ]}
              label=""
              onChange={(_e) => handleDefaultAddressSelection()}
              type="radio"
            />
          </Details>
        </React.Fragment>
      )}
    </QuestionPage>
  ) : (
    <QuestionPage title={t("pages.claimsAddress.title")} onSave={handleSave}>
      <FieldsetAddress
        errors={appLogic.errors}
        label={t("pages.claimsAddress.sectionLabel")}
        hint={t("pages.claimsAddress.hint")}
        {...residentialAddressProps}
      />
      <InputChoiceGroup
        {...getFunctionalInputProps("has_mailing_address")}
        choices={[
          {
            checked: has_mailing_address === false,
            label: t("pages.claimsAddress.choiceYes"),
            value: "false",
          },
          {
            checked: has_mailing_address === true,
            label: t("pages.claimsAddress.choiceNo"),
            value: "true",
          },
        ]}
        label={t("pages.claimsAddress.hasMailingAddressLabel")}
        hint={t("pages.claimsAddress.hasMailingAddressHint")}
        type="radio"
      />
      <ConditionalContent
        fieldNamesClearedWhenHidden={["mailing_address"]}
        getField={getField}
        clearField={clearField}
        updateFields={updateFields}
        visible={has_mailing_address}
      >
        <FieldsetAddress
          errors={appLogic.errors}
          label={t("pages.claimsAddress.mailingAddressLabel")}
          hint={t("pages.claimsAddress.mailingAddressHint")}
          addressType="mailing"
          {...mailingAddressProps}
        />
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withBenefitsApplication(Address);
